import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import Typography from '@mui/material/Typography';

import Container from 'components/common/Container';
import HeaderTab from 'components/detailCard/HeaderTab';
import TabPanel from 'components/detailCard/TabPanel';
import api from 'utils/api';

import { useAppDispatch } from 'redux/hooks';
import {
  updateActivePoint,
  transformToPoint
} from 'redux/slice/mapControllerSlice';
import SvgIcon from '@mui/material/SvgIcon';

// =============================================================================
import { ReactComponent as Cloud } from 'static/icons/Cloud.svg';
import { ReactComponent as Wind } from 'static/icons/Wind.svg';
import { ReactComponent as Water } from 'static/icons/Water.svg';
import { ReactComponent as Salinity } from 'static/icons/Salinity.svg';
import { ReactComponent as Sun } from 'static/icons/Sun.svg';

// =============================================================================
export const cardTransforms = [
  'scale(2.5) translate(0%, -20%)',
  'scale(2.5) translate(25%, -30%)',
  'scale(2.5) translate(-30%, 40%)'
];

// =============================================================================
interface DetailCardProps {
  buoyCard: any;
  setOverline: any;
  disabled: boolean[];
}

export default function DetailCard(props: DetailCardProps) {
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState(0);

  const cardHeaders = props.buoyCard.map((card: any) => card.acronym);
  const cardDescriptions = props.buoyCard.map((card: any) => ({
    title: card.title,
    content: card.description
  }));
  const [cardData, setCardData] = React.useState<any>(null);

  const params = [
    ['wind_speed', 'm/s', 1],
    ['water_temperature', 'degree celcius', 2],
    ['air_temperature', 'degC', 2],
    ['air_pressure', 'mbar', 1],
    ['salinity', 'psu', 2],
    ['solar_irradiance', 'W/m2', 1]
  ];

  useEffect(() => {
    (async () => {
      const resp: any = await api.get(`/public/latestData/SJI`);
      const data = resp.data;

      const res = params.map((param: any) => {
        const formattedParam = param[0];
        return `${data[formattedParam].value} ${data[formattedParam].unit}`;
      });
      props.setOverline(
        `Data shown as of ${moment(Number(data.latestTs))
          .tz('Asia/Singapore')
          .format('hh:mmA, Do MMM')}`
      );
      setCardData(res);
    })();
  }, []);

  return (
    <div>
      <HeaderTab
        headers={cardHeaders}
        value={value}
        onChange={handleChange}
        disabled={props.disabled}
      />
      <div style={{ height: 32 }} />
      {generateTabPanels(cardDescriptions)}
    </div>
  );

  function handleChange(event: React.SyntheticEvent, newValue: number) {
    setValue(newValue);
    dispatch(updateActivePoint(cardHeaders[newValue]));
    dispatch(transformToPoint(cardTransforms[newValue]));
  }

  function generateTabPanels(descriptions: any) {
    return descriptions.map((description: any, index: number) => {
      return (
        <TabPanel value={value} index={index} key={index}>
          <Container>
            <Typography variant='body_large_strong' color='blue.900'>
              {description.title}
            </Typography>
            <div style={{ height: 8 }} />
            <Typography variant='body_regular' color='blue.900'>
              {description.content}
            </Typography>
          </Container>

          {cardData ? WhiteCardSection(cardData) : null}
        </TabPanel>
      );
    });
  }
}

// =============================================================================

const WhiteCardSection = (data: any) => {
  // const getCleanDisplay = (info: any, unit: string = '') => {
  //   return `${info?.value}${
  //     info?.value === 'N/A' ? '' : ' ' + (unit === '' ? info?.units : unit)
  //   }`;
  // };

  return (
    <div
      style={{
        display: 'flex',
        overflow: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
      }}
    >
      <WhiteCard icon={<Wind />} title='Wind' content={`${data[0]}`} />
      <WhiteCard
        icon={<Water />}
        title='Water Temperature'
        content={`${data[1]}`}
      />
      <WhiteCard
        icon={<Cloud />}
        title='Air Temperature'
        content={`${data[2]}`}
      />
      <WhiteCard icon={<Cloud />} title='Air Pressure' content={`${data[3]}`} />
      <WhiteCard icon={<Salinity />} title='Salinity' content={`${data[4]}`} />
      <WhiteCard
        icon={<Sun />}
        title='Solar IRRADIANCE'
        content={`${data[5]}`}
      />
    </div>
  );
};

// =============================================================================
interface WhiteCardProps {
  icon: React.ReactNode;
  title: string;
  content?: string;
}

const WhiteCard = (props: WhiteCardProps) => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        minWidth: 100,
        width: '30%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        margin: 16,
        padding: 16,
        borderRadius: 8
      }}
    >
      <SvgIcon>{props.icon}</SvgIcon>
      <div style={{ height: 8 }} />
      <Typography variant='caption' color='blue.900'>
        {props.title}
      </Typography>
      <div style={{ height: 8 }} />
      <Typography variant='body_regular_strong' color='blue.900'>
        {props.content}
      </Typography>
    </div>
  );
};
