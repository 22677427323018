import React, { useEffect } from 'react';
import Card from '@mui/material/Card';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  updateActivePoint,
  transformToPoint
} from 'redux/slice/mapControllerSlice';

import HeaderTab from 'components/detailCard/HeaderTab';
import TabPanel from 'components/detailCard/TabPanel';
import BuoyDescription from 'components/detailCard/BuoyDescription';
import BuoyInfo from 'components/detailCard/BuoyInfo';

// =============================================================================
interface DetailCardProps {
  headers: string[];
  descriptions: { title: string; content: string }[];
  transforms: string[];
  infos: {
    wind_direction: string;
    water_temperature: string;
    air_temperature: string;
    air_pressure: string;
    salinity: string;
    solary_irridance: string;
  }[];
  disabled: boolean[];
  style?: React.CSSProperties;
}

export default function DetailCard(props: DetailCardProps) {
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState(0);

  const transform = useAppSelector((state) => state.mapController.transform);
  const activePoint = useAppSelector(
    (state) => state.mapController.activePoint
  );

  useEffect(() => {
    console.log(transform);
    const newValue = props.headers.indexOf(activePoint);
    if (newValue != -1) setValue(newValue);
  }, [activePoint]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    dispatch(updateActivePoint(props.headers[newValue]));
    dispatch(transformToPoint(props.transforms[newValue]));
  };

  return (
    <div>
      <Card sx={props.style}>
        <HeaderTab
          value={value}
          onChange={handleChange}
          headers={props.headers}
          disabled={props.disabled}
        />

        <div>
          {props.descriptions.map((description, index) => (
            <TabPanel value={value} index={index} key={index}>
              <BuoyDescription description={description} />
            </TabPanel>
          ))}
        </div>

        <div>
          {props.infos.map((info, index) => (
            <TabPanel value={value} index={index} key={index}>
              <BuoyInfo infos={props.infos} />
            </TabPanel>
          ))}
        </div>
      </Card>
    </div>
  );
}
